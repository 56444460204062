<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <other-card :venueItem="item" :isGsk="isGsk"></other-card>
    </div>
  </div>
</template>
<script>
import otherCard from "@/view/supplierQuote/components/otherQuote/otherCard";
export default {
  components: {
    otherCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      venuesList: []
    }
  },
  watch: {
    supplierInfo () {
      let others = this.supplierInfo.others
      this.venuesList = others
    }
  },
  created() {
    let others = this.supplierInfo.others
    this.venuesList = others
  }
}
</script>
