<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <venue-card :venueItem="item" :isGsk="isGsk"></venue-card>
    </div>
  </div>
</template>
<script>
import venueCard from "@/view/supplierQuote/components/venueQuote/venueCard";
export default {
  components: {
    venueCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      venuesList: []
    }
  },
  watch: {
    supplierInfo () {
      let venues = this.supplierInfo.venues || []
      let venueInfo = venues.filter(ele => {return ['酒店会场'].includes(ele.itemTypeTxt)});
      let teaList = venues.filter(ele => {return ['会场茶歇'].includes(ele.itemTypeTxt)});
      venueInfo.forEach(ele => {
        ele.teaList = teaList.filter(li => { return ele.itemId == li.itemId});
      });
      this.venuesList = venueInfo
    }
  },
  created() {
    let venues = this.supplierInfo.venues || []
    let venueInfo = venues.filter(ele => {return ['酒店会场'].includes(ele.itemTypeTxt)});
    let teaList = venues.filter(ele => {return ['会场茶歇'].includes(ele.itemTypeTxt)});
    venueInfo.forEach(ele => {
      ele.teaList = teaList.filter(li => { return ele.itemId == li.itemId});
    });
    this.venuesList = venueInfo
  }
}
</script>
