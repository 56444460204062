<template>
  <transition name="fade">
    <div class="selectList" v-if="isShowPopup">
      <div class="selectListMask" @click='selectCallbackMethod'></div>
      <ul class="selectListContent background-color-FFF">
        <li :class="['selectListInner','text-align-center','font16','color3', {'selectedInner':item.val==value}]"
            v-for='(item, index) in listData' :key='index'
            @click='chooseItem(item)'>
          {{item.name || item.txt}}
          <span v-if="item.price" class="font14 colorError">参考价： {{ item.price || '无' }}</span>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
// 这个组件适用于选择
//     酒店会场
//     酒店住宿--房型
export default {
  props: {
    value:{
      type:String,
      default:''
    },
    listData:{
      type:Array,
      default () {
        return []
      }
    },
    isShowPopup:{
      type:Boolean,
      default: false
    },
    selectCallbackMethod:{
      type:Function,
      default:function() {}
    }
  },
  methods: {
    // 选择类型
    chooseItem(item){
      // this.$emit('input',item.val); // 暂未使用 v-model绑定
      this.selectCallbackMethod(item);
    }
  },
}
</script>

<style lang="scss" scoped>
.selectList{
  position: fixed;
  top: .44rem;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(#000000,.3);
}
.selectListMask{
  position: fixed;
  top: .44rem;
  width: 100%;
  bottom: 0;
}
.selectListContent{
  position: fixed;
  width: 100%;
  max-height: 4rem;
  overflow-y: scroll;
  bottom: 0;
}
.selectListInner{
  padding:0.15rem;
  border-bottom: 1px solid #cccccc;
  background: #fff;
}
.selectedInner{
  color: #0671D5 !important;
}
.lastSelect{
  border-bottom: 0;
}
.color3{
  color:#333333;
}
</style>
