<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <room-card :venueItem="item" :isGsk="isGsk"></room-card>
    </div>
  </div>
</template>
<script>
import roomCard from "@/view/supplierQuote/components/roomQuote/roomCard";
export default {
  components: {
    roomCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      venuesList: []
    }
  },
  watch: {
    supplierInfo () {
      let rooms = this.supplierInfo.rooms
      this.venuesList = rooms
    }
  },
  created() {
    let rooms = this.supplierInfo.rooms
    this.venuesList = rooms
  }
}
</script>
