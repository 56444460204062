<template>
  <section>
    <sm_header title='酒店报价' :isBack="false"></sm_header>
    <div class="smallVenueTitle">
      <venue-info :supplierInfo="supplierInfo"></venue-info>
      <venue-type :supplierInfo="supplierInfo" @updateItem="updateItem"></venue-type>
    </div>
    <div class="venueBox">
      <venue-quote :supplierInfo="supplierInfo" v-show="['酒店会场'].includes(isSelected)" :isGsk="isGsk"></venue-quote>
      <meal-quote :supplierInfo="supplierInfo" v-show="['酒店用餐'].includes(isSelected)" :isGsk="isGsk"></meal-quote>
      <room-quote :supplierInfo="supplierInfo" v-show="['酒店住宿'].includes(isSelected)" :isGsk="isGsk"></room-quote>
      <other-quote :supplierInfo="supplierInfo" v-show="['酒店其他'].includes(isSelected)" :isGsk="isGsk"></other-quote>
      <supplier-file v-show="['支持文件'].includes(isSelected)"></supplier-file>
      <prepayments v-show="['预付款'].includes(isSelected)"></prepayments>
      <meeting-person-info v-show="['上会人员信息'].includes(isSelected)"></meeting-person-info>
      <clause-page v-if="['报价有效期及取消条款'].includes(isSelected)"></clause-page>
    </div>
    <footer class="footer_module" v-if="isEdit">
      <div class="btn_container">
        <p @click="saveDetail" class="btn_item"  style="width: 100%; max-width: 1.68rem;">
          <span>提交报价</span>
        </p>
      </div>
    </footer>
  </section>
</template>
<script>
import VenueInfo from "@/view/supplierQuote/components/venueInfo";
import venueType from "@/view/supplierQuote/components/venueType";
import venueQuote from './components/./venueQuote/venueQuote'
import mealQuote from "@/view/supplierQuote/components/mealQuote/mealQuote";
import otherQuote from "@/view/supplierQuote/components/otherQuote/otherQuote";
import roomQuote from "@/view/supplierQuote/components/roomQuote/roomQuote";
import supplierFile from "@/view/supplierQuote/components/supplierFile";
import prepayments from "@/view/supplierQuote/components/prepayments";
import meetingPersonInfo from "@/view/supplierQuote/components/meetingPersonInfo";
import clausePage from "@/view/supplierQuote/components/clausePage";
export default {
  components: {
    VenueInfo,
    venueType,
    venueQuote,
    mealQuote,
    otherQuote,
    roomQuote,
    supplierFile,
    prepayments,
    meetingPersonInfo,
    clausePage
  },
  data () {
    return {
      supplierInfo: {},
      isSelected: '',
      rightTxt: '',
      btnInfo: {},
      isEdit: false,
      isGsk: false
    }
  },
  created() {
    this.getInvoiceTypeList()
    this.isGsk = ['2a0edd6a-b7f9-11ed-8b12-26d545d4edc5'].includes(this.$SmartStorage.get('tenantId'))
  },
  methods:{
    async GetF1QuotedPriceTaskList () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let res = await this.$service.GetF1QuotedPriceTaskList(params);
      if (res && res.success) {
        this.queryVenueSuppliers(res.content);
      }
    },
    async queryVenueSuppliers (supplierInfo) {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let res = await this.$service.QueryVenueSuppliers(params);
      if (res && res.success) {
        const dataArr = ['venues', 'meals', 'rooms', 'others'];
        let supplier = res.content.filter(ele => {return [this.$SmartStorage.get('supplierId')].includes(ele.supplierId)})[0]
        let result = supplierInfo.filter((li) => {
          if (this.$SmartStorage.get('orderId')) {
            return li.supplierId == this.$SmartStorage.get('supplierId') && this.$SmartStorage.get('orderId') == li.orderId
          }
          return li.supplierId == this.$SmartStorage.get('supplierId')
        })[0]
        let result2 =[0, '0'].includes(result.taskStatus)
        this.isEdit = result2
        this.$SmartStorage.set('isEdit', result2)
        supplier.items.forEach(li => {
          li.expand = true;
          supplier[dataArr[li.itemType - 1]] = supplier[dataArr[li.itemType - 1]] || [];
          supplier[dataArr[li.itemType - 1]].push(li);
        });
        this.supplierInfo = supplier
        this.$SmartStorage.set('supplierInfo', this.supplierInfo)
      }
    },
    updateItem (val) {
      this.isSelected = val
    },
    saveDetail () {
      this.$dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        message: '是否确定提交报价',
      }).then(async() => {
        let params = {
          actionId: this.$SmartStorage.get('actionId'),
          formData: JSON.stringify({
            supplierId: this.$SmartStorage.get('supplierId'),
            orderId: this.$SmartStorage.get('orderId')
          }),
          nodeId: this.$SmartStorage.get('nodeId'),
          processId: this.$SmartStorage.get('proposalId'),
          taskData:  JSON.stringify({
            supplierId: this.$SmartStorage.get('supplierId'),
            orderId: this.$SmartStorage.get('orderId')
          }),
          taskId: this.$SmartStorage.get('taskId'),
          orderId: this.$SmartStorage.get('orderId'),
        }

        let headers = {}
        if (this.$SmartStorage.get('authMobile')) {
          headers = {
            headers: {"authmobile": this.$SmartStorage.get('authMobile')}
          }
        }
        let res = await this.$service.ProcessTask(params, headers)
        if (res.success) {
          this.SaveMiceAssistantInfo()
        }
      }).catch(() => {});
    },
    async SaveMiceAssistantInfo () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
        itemId: null,
        authmobile: this.$SmartStorage.get('authMobile'),
        supplierId: this.$SmartStorage.get('supplierId'),
      }
      let res = await this.$service.SaveMiceAssistantInfo(params)
      if (res && res.success) {
        this.GetF1QuotedPriceTaskList()
        this.$router.push('/quoteSuccess')
      }
    },
    // 获取发票类型
    async getInvoiceTypeList () {
      let params = {
        collection: 'cfg-supplier-options',
        useCaching: false,
        cachingKey: '',
        filter: {
          tenantCode: { '$in': ['*', this.isGsk ? 'GSK' : '*'] },
          tenantId: { '$in': ['*', this.$SmartStorage.get('tenantId')] },
        },
        projection: {
          options: 1
        }
      }
      let res = await this.$service.getsettings(params)
      if (res && res.success) {
        this.$SmartStorage.set('invoiceTypeList', res.content.options)
        this.GetF1QuotedPriceTaskList()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.smallVenueTitle {
  position: fixed;
  top: 0.44rem;
  width: 100%;
  left: 0;
  z-index: 1;
  background: #fff;
}
.venueBox {
  margin: 1.8rem 0 .7rem;
}
.footer_module {
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  height: 0.44rem;
  line-height: .44rem;
  text-align: center;
  background-color: white;
  z-index: 1900;
  border-top: 1px solid #EEEEEE;
  .btn_container {
    position: absolute;
    top: 0px;
    right: 0px;
    min-width: calc(100% - 1.68rem);
    height: 100%;
    text-align: right;
    .btn_item {
      display: inline-block;
      height: 100%;
      font-size: .15rem;
      text-align: center;
      background-color: var(--themeColor);;
      span {
        color: white;
      }
    }
  }
}
</style>
