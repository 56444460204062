import { render, staticRenderFns } from "./clausePage.vue?vue&type=template&id=d6bcd4de&scoped=true&"
import script from "./clausePage.vue?vue&type=script&lang=js&"
export * from "./clausePage.vue?vue&type=script&lang=js&"
import style0 from "./clausePage.vue?vue&type=style&index=0&id=d6bcd4de&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6bcd4de",
  null
  
)

export default component.exports