import { render, staticRenderFns } from "./mealCard.vue?vue&type=template&id=9048e324&scoped=true&"
import script from "./mealCard.vue?vue&type=script&lang=js&"
export * from "./mealCard.vue?vue&type=script&lang=js&"
import style0 from "./mealCard.vue?vue&type=style&index=0&id=9048e324&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9048e324",
  null
  
)

export default component.exports