<template>
  <div>
    <div class="fileCard" v-for="(item, index) in mettingPersonInfoData" :key="index">
      <div class="fileInfoItem">
        <p><span>人员类型:</span><span>{{item.typeTxt}}</span></p>
        <p><span>工作类型:</span><span>{{item.jobTypesTxt}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>供应商名称:</span><span>{{item.supplierName}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>人员:</span><span>{{item.name}}({{item.mobile}})</span></p>
      </div>
    </div>
    <div class="fileCard" v-if="!mettingPersonInfoData.length">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      mettingPersonInfoData: []
    }
  },
  created() {
    this.queryProtocol()
  },
  methods: {
    async queryProtocol () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        tenantId: this.$SmartStorage.get('tenantId'),
      }
      let res = await this.$service.getWorkers(params)
      if (res && res.success) {
        this.mettingPersonInfoData = res.content;
      }
    }
  }
}
</script>
<style scoped="scoped" lang="scss">
.fileCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: 0.25rem .1rem .1rem;
  background: #ffffff;
  position: relative;
  padding: .1rem;
  .fileInfoItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .05rem;
    p{
      flex: 1 1 50%;
      text-align:left
    }
  }
}
</style>
