<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <meal-card :venueItem="item" :isGsk="isGsk"></meal-card>
    </div>
  </div>
</template>
<script>
import mealCard from "@/view/supplierQuote/components/mealQuote/mealCard";
export default {
  components: {
    mealCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      venuesList: []
    }
  },
  watch: {
    supplierInfo () {
      let meals = this.supplierInfo.meals
      this.venuesList = meals
    }
  },
  created() {
    let meals = this.supplierInfo.meals
    this.venuesList = meals
  }
}
</script>
