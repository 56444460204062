<template>
  <div>
    <div class="fileCard">
      <div class="fileInfoItem">
        <p><span>供应商名称:</span><span>{{quoteTermsData.supplierName}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>总报价:</span><span>￥{{(quoteTermsData.quote || 0).toFixed(2)}}</span></p>
      </div>
      <div class="normalInputRow" style="text-align: left">
        <p><span style="color:red">*</span>价格有效期及资源保留期</p>
        <iInput noCancel="false" v-model="quoteTermsData.quoteDeadTime" :disabled="true" type="text" placeholder="请选择日期" @click.native.stop='selectTime("quoteDeadTime")'></iInput>
      </div>
      <div class="normalInputRow"  style="text-align: left">
        <p><span style="color:red">*</span>取消条款</p>
        <iInput noCancel="false" v-model="quoteTermsData.dateNoFee" :disabled="true" type="text" placeholder="请选择日期" @click.native.stop='selectTime("dateNoFee")'></iInput>
        <p>前取消，不收取费用</p>
      </div>
      <div class="normalInputRow"  style="text-align: left">
        <iInput noCancel="false" title="" v-model="quoteTermsData.datePercentFee" :disabled="true" type="text" placeholder="请选择日期" @click.native.stop='selectTime("datePercentFee")'></iInput>
        前取消，按合同金额
        <iInput noCancel="false" title="" v-model="quoteTermsData.lossPercent" :disabled="!isEdit" type="tel" style="width: 20%;display: inline-block" placeholder="请输入"></iInput>
        %收取费用
      </div>
      <div class="normalInputRow"  style="text-align: left">
        <iInput noCancel="false" title="" v-model="quoteTermsData.dateAllFee" :disabled="true" type="text" placeholder="请选择日期" @click.native.stop='selectTime("dateAllFee")'></iInput>
        前取消，收取全部费用
      </div>
      <div class="normalInputRow"  style="text-align: left">
        其他条款
        <iInput noCancel="false" v-model="quoteTermsData.others" :disabled="!isEdit" type="textarea" placeholder="请填写其他条款"></iInput>
      </div>
    </div>
    <div class="largeBtn5" v-if="isEdit" @click.stop="editInfo()">保存</div>
  </div>
</template>
<script>
import dateController from "@/components/datePicker/dateController";
import vueFilter from "@/common/filter/vuefilter";
export default {
  mixins: [dateController],
  data () {
    return {
      quoteTermsData: {},
      startTime: "", // 时间选择限制 开始日期
      endTime: "", // 时间选择限制 结束日期
      isEdit: true
    }
  },
  created() {
    this.startTime = this.getNewDate();
    this.endTime = this.getEndDate();
    this.queryClauses()
  },
  methods: {
    async queryClauses () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId') || null,
        tenantId: this.$SmartStorage.get('tenantId'),
        taskId: this.$SmartStorage.get('taskId'),
      }
      let res = await this.$service.queryClauses(params)
      if (res && res.success) {
        if (res.content) {
          res.content.forEach(ele => {
            if ([ele.supplierId].includes(this.$SmartStorage.get('supplierId'))) {
              this.isEdit = this.$SmartStorage.get('isEdit')
              this.quoteTermsData = {
                quoteDeadTime: vueFilter.formatDate(ele.quoteDeadTime, 'yyyy/MM/dd'),
                dateNoFee: vueFilter.formatDate(ele.dateNoFee, 'yyyy/MM/dd'),
                datePercentFee: vueFilter.formatDate(ele.datePercentFee, 'yyyy/MM/dd'),
                lossPercent: ele.lossPercent,
                others: ele.others,
                supplierName: ele.supplierName,
                quote: ele.quote,
                dateAllFee: vueFilter.formatDate(ele.dateAllFee, 'yyyy/MM/dd'),
              }
            }
          })
        }
      }
    },
    selectTime (params) {
      if (!this.isEdit) {
        return
      }
      this.openSelectTime(this.startTime,this.endTime,this.quoteTermsData[params] || vueFilter.formatDate(new Date()),params, 'yyyy/MM/dd', 'date');
    },
    // 获取当前 年-月-日
    getNewDate() {
      let newDate = new Date(),
          y = newDate.getFullYear(),
          m = newDate.getMonth() + 1,
          d = newDate.getDate();
      return y + "/" + m + "/" + d;
    },
    // 获取结束日期 年-月-日
    getEndDate() {
      let newDate = new Date(),
          y = newDate.getFullYear() + 1,
          m = newDate.getMonth() + 1,
          d = newDate.getDate();
      return y + "/" + m + "/" + d;
    },
    setSelectTime (target, time, formatDate) {
      this.$set(this.quoteTermsData, target,vueFilter.formatDate(time,formatDate))
    },
    editInfo () {
      let toast = this.veryFilter();
      if (toast) {
        this.$toast(toast);
        return false;
      }
      this.saveClause()
    },
    veryFilter () {
      let toast = '';
      if (!this.quoteTermsData.quoteDeadTime) {
        toast = '请选择价格有效期及资源保留期!';
        return toast;
      }
      if (!this.quoteTermsData.dateNoFee) {
        toast = '请选择不收取费用截止日期!';
        return toast;
      }
      if (!this.quoteTermsData.datePercentFee) {
        toast = '请选择收取费用按合同金额百分比截止日期！';
        return toast;
      }
      if (!this.quoteTermsData.lossPercent) {
        toast = '请填写收取费用百分比!！';
        return toast;
      }
      if (!this.quoteTermsData.dateAllFee) {
        toast = '请选择收取全部费用截止日期！';
        return toast;
      }
      return null;
    },
    async saveClause () {
      let params = {
        quoteDeadTime: this.quoteTermsData.quoteDeadTime,
        dateNoFee:this.quoteTermsData.dateNoFee,
        datePercentFee:this.quoteTermsData.datePercentFee,
        lossPercent:this.quoteTermsData.lossPercent,
        dateAllFee:this.quoteTermsData.dateAllFee,
        others:this.quoteTermsData.others,
        supplierId: this.$SmartStorage.get('supplierId'),
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId') || null,
      }
      let res = await this.$service.SaveSupplierClause(params)
      if (res && res.success) {
        this.$toast("保存成功！");
      }
    }
  }
}
</script>
<style scoped="scoped" lang="scss">
.fileCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: 0.25rem .1rem .1rem;
  background: #ffffff;
  position: relative;
  padding: .1rem;
  .fileInfoItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .05rem;
    p{
      flex: 1 1 50%;
      text-align:left
    }
  }
}
</style>
