<template>
  <div class="venueInfo">
    <div class="orderHead">
      <div class="venueName">
        {{supplierInfo.supplierName}}
        <div class="venueType">
          <span>
              {{supplierInfo.payModeTxt}}
          </span>
          <span>
              {{supplierInfo.statusTxt}}
          </span>
        </div>
      </div>
      <div class="venueName2">
        <p>{{supplierInfo.contacter}} <span v-if="supplierInfo.contactMobile">({{supplierInfo.contactMobile}})</span></p>
        <div class="venueName3">
          总报价： ￥{{(amount).toFixed(2)}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data () {
    return {
      amount: 0
    }
  },
  created() {
    this.queryVenueSuppliers()
    this.$eventHub.$on('getVenueQuote', () => {
      this.queryVenueSuppliers()
    })
  },
  methods: {
    async queryVenueSuppliers () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let res = await this.$service.QueryVenueSuppliers(params);
      if (res && res.success) {
        let supplier = res.content.filter(ele => {return [this.$SmartStorage.get('supplierId')].includes(ele.supplierId)})[0]
        this.amount = supplier.quote
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.venueInfo {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  padding: 0 .1rem;
  .venueName {
    padding: 0.1rem 0.1rem 0.04rem;
    text-align: left;
    font-size: .16rem;
    font-weight: bold;
    color: #13161A;
    letter-spacing: -1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .venueName2 {
    padding: 0.1rem 0.1rem 0.04rem;
    text-align: left;
    font-size: .14rem;
    font-weight: bold;
    color: #13161A;
    letter-spacing: -1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .venueName3 {
    text-align: left;
    font-size: .14rem;
    color: #13161A;
    letter-spacing: -1px;
  }
  .venueType {
    span {
      color: var(--themeColor);
      font-size: .13rem;
      font-weight: 200;
      margin-right: 0.05rem;
      display: inline-block;
      padding: 0.02rem 0.05rem;
      position: relative;
      border-radius: 0.04rem;
      overflow: hidden;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .1;
        left: 0;
        bottom: 0;
        background-color: var(--themeColor);
      }
    }
  }
}
</style>
