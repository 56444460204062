<template>
  <div>
    <div class="fileCard" v-for="(item, index) in prepaymentData" :key="index">
      <div class="fileInfoItem">
        <p><span>供应商:</span><span>{{item.supplierName}}</span></p>
        <p><span>是否需要预付款:</span><span>{{item.isRequired ? "需要" : "不需要"}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>预付款:</span><span>{{item.amount}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>要求付款时间:</span><span>{{item.deadline | formatDate('yy-MM-dd hh:mm:ss')}}</span></p>
      </div>
      <div class="fileCard" v-if="!prepaymentData.length">
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      prepaymentData: []
    }
  },
  created() {
    this.queryProtocol()
  },
  methods: {
    async queryProtocol () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId') || null,
        tenantId: this.$SmartStorage.get('tenantId'),
        taskId: this.$SmartStorage.get('taskId'),
      }
      let res = await this.$service.queryAdvances(params)
      if (res && res.success) {
        this.prepaymentData = res.content;
      }
    }
  }
}
</script>
<style scoped="scoped" lang="scss">
.fileCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: 0.25rem .1rem .1rem;
  background: #ffffff;
  position: relative;
  padding: .1rem;
  .fileInfoItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .05rem;
    p{
      flex: 1 1 50%;
      text-align:left
    }
  }
}
</style>
