<template>
  <div>
    <div class="venueCard">
      <div class="venueTtitle">{{venueItem.itemTypeTxt}}</div>
      <div class="venueInfo">
        <div><span>名称:</span><span>{{ venueItem.itemName }}</span></div>
        <div><span>数量:</span><span>{{ venueItem.qty || venueItem.qty1 }}</span></div>
      </div>
      <div class="venueInfo">
        <div><span>开始时间:</span><span>{{ venueItem.dtStart | formatDate('yyyy-MM-dd hh:mm')}}</span></div>
      </div>
      <div class="venueInfo">
        <div><span>结束时间:</span><span>{{ venueItem.dtEnd | formatDate('yyyy-MM-dd hh:mm')}}</span></div>
      </div>
      <div class="venueInfo">
        <div><span>备注:</span><span>{{ venueItem.descr }}</span></div>
      </div>
      <div>
        <p class="quoteTips">请务必注明会议室名称、面积、楼层、超出参考价原因</p>
        <div class="venueTtitle felxBtn" @click="isShow = !isShow">
          <span>费用类型: {{formData.costType}}</span>
          <div class="retract">
            <svg class="icon" aria-hidden="true" v-show="isShow">
              <use xlink:href="#iconarrow-up"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-show="!isShow">
              <use xlink:href="#iconarrowdown"></use>
            </svg>
          </div>
        </div>
        <div class="processBody" :class="isShow ? 'showBody' : 'hiddenBody'">
          <div>
            <van-field v-model="formData.price" label="单价" placeholder="请输入单价" type="number" :required="true" :disabled="!isEdit"/>
          </div>
          <div>
            <van-field v-model="formData.outPrice" label="对外价格" placeholder="请填写对外价格" type="number" :disabled="!isEdit"/>
          </div>
          <div>
            <van-field v-model="formData.qty" label="数量" :disabled="true"/>
          </div>
          <div>
            <van-field :value="updateAmount" label="小计" placeholder="请输入单价" type="number" :disabled="true"/>
          </div>
          <div>
            <van-field v-model="formData.invoiceType" label="发票类型" placeholder="请选择发票类型" :required="true" :disabled="true" @click.native.stop='selectInvoiceType'/>
          </div>
          <div>
            <van-field v-model="formData.taxRate" label="税率" type="number" placeholder="请输入税率" :required="true" :disabled="!isEdit" @blur="onInput"/>
          </div>
          <div>
            <van-field v-model="formData.descr" label="报价说明" rows="2" autosize type="textarea"  maxlength="400" show-word-limit placeholder="请输入报价说明" :disabled="!isEdit"/>
          </div>
          <div>
            <van-field v-model="formData.serviceDescr" label="服务描述" rows="2" autosize type="textarea" :required="isGsk" maxlength="400" show-word-limit placeholder="请输入服务描述" :disabled="!isEdit"/>
          </div>
        </div>
      </div>
      <div class="fileTitle quote">
        支持文件
        <FinderFrame :gateway="gateway" ref="FinderFrame" :isPreview="true" :h5FilePreview="false" :actions="actions"
                     :onlyPreview="!isEdit" :filterData="mappingData" :isMapping="true" :uploadTimeout="120000" @uploadCallback="() => {}" />
      </div>
      <div class="largeBtn5" v-if="isEdit" @click.stop="editInfo()">保存报价</div>
    </div>
    <select-type-list :isShowPopup="isShowPopup" :listData="options" :selectCallbackMethod="selectMethod"></select-type-list>
  </div>
</template>
<script>
import {FinderFrame} from "smart-filer-vue";
import "smart-filer-vue/lib/index.css";
import { Field } from 'vant';
import selectTypeList from "../selectTypeList.vue";
export default {
  name: 'otherCard',
  components: {
    FinderFrame,
    [Field.name]: Field,
    selectTypeList
  },
  props: {
    venueItem: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [],
      invoiceTypeList: [],
      quotation: {},
      formData: [],
      fileKeys: [],
      isShow: true,
      isEdit:true,
      isShowPopup: false,
      gateway: process.env.VUE_APP_GATEWAY,
      actions: [
        {actionType: "carame", actionLabel: "拍照"},
        { actionType: "album", actionLabel: "文件", accepts: "*/*,.doc,.docx,.pdf,.xls,.xlsx" }
      ],
      mappingData: {
        processId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        // relatedId: this.$SmartStorage.get('orderId') || null,
        supplierId: this.$SmartStorage.get('supplierId'),
        catalog: "报价文件"
      }
    }
  },
  watch: {
    venueItem(){
      this.GetItemQuote()
    }
  },
  computed: {
    updateAmount () {
      return  (this.formData.price ||0) * (this.formData.qty || 0)
    },
  },
  created() {
    this.isEdit = this.$SmartStorage.get('isEdit')
    this.invoiceTypeList = this.$SmartStorage.get('invoiceTypeList')
    this.GetItemQuote()
  },
  methods: {
    async GetItemQuote () {
      let params = {
        ProposalId: this.$SmartStorage.get('proposalId'),
        ItemId: this.venueItem.itemId,
        SupplierId: this.$SmartStorage.get('supplierId'),
      };
      let res = await this.$service.GetItemQuote(params);
      if (res && res.success) {
        this.quotation = res.content;
        console.log(this.venueItem)
        // this.GetRequiredQuotes()
        this.int()
      }
    },
    int () {
      let expenses = this.venueItem.expenses[0]
      this.formData = {
        expenseId: expenses.expenseId,
        costType: expenses.costType,
        qty: expenses.qty,
        price: expenses.price,
        amount: expenses.amount,
        outPrice: expenses.outPrice,
        invoiceType: expenses.invoiceType,
        taxRate: expenses.taxRate,
        descr: expenses.descr,
        serviceDescr: expenses.serviceDescr,
        files: this.fileKeys,
      }
    },
    async GetRequiredQuotes () {
      let params = {
        tenantId: this.$SmartStorage.get('tenantId'),
        miceId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
      }
      let res = await this.$service.GetRequiredQuotes(params);
      if (res && res.success) {
        let config = res.content.filter((item) => {
          return item.costType;
        })
        this.formData = {
          expenseId: config[0].expenseId,
          costType: config[0].costType,
          price: config[0].price,
          qty: config[0].qty,
          amount: config[0].amount,
          outPrice: config[0].outPrice,
          invoiceType: config[0].invoiceType,
          taxRate: config[0].taxRate,
          descr: config[0].descr,
          serviceDescr: config[0].serviceDescr,
          files: this.fileKeys
        }
      }
    },
    editInfo () {
      let toast = this.veryFilter();
      if (toast) {
        this.$toast(toast);
        return false;
      }
      let res = this.$refs.FinderFrame.fileList
      this.fileKeys = res.map(ele => ele.fileKey)
      this.SaveQuote()
    },
    onInput() {
      const value = this.formData.taxRate;
      if (value === null || value === undefined || value === '') {
        return null; // If the control is empty, don't show an error
      }
      const regExp = /^[1-9]\d*$/;
      if (regExp.test(value)) {
        return null; // The control is a positive integer, so return null (no error)
      } else {
        this.formData.taxRate = ''
        this.$toast("请填写有效的正整数！");
      }
    },
    veryFilter() {
      let toast = '';
      if (!this.formData.price) {
        toast = '请填写单价！';
        return toast;
      }
      if (!this.formData.invoiceType) {
        toast = '请选择发票类型！';
        return toast;
      }
      if (!this.formData.taxRate) {
        toast = '请输入税率！';
        return toast;
      }
      if (this.isGsk && !this.formData.serviceDescr) {
        toast = '请输入服务描述！';
        return toast;
      }
      return null;
    },
    async SaveQuote () {
      let taxRate = Number(this.formData.taxRate) / 100;
      let amount =  (this.formData.price ||0) * (this.formData.qty || 0)
      let params = {
        tenantId: this.$SmartStorage.get('tenantId'),
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId') || null,
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
        quotes: [{
          expenseId: this.formData.expenseId,
          costType: this.formData.costType, //费用类型
          price: this.formData.price, //单价
          qty: this.formData.qty, //数量(用餐人数/间数…)
          outPrice: this.formData.outPrice, //数量(用餐人数/间数…)
          amount: amount, //小计
          minQty: this.formData.minQty, //保底数量
          invoiceType: this.formData.invoiceType, //发票类型
          taxRate: this.formData.taxRate, //税率
          tax: Number((amount / (1 + taxRate) * taxRate).toFixed(2)), // 税额
          descr: this.formData.descr, //说明
          serviceDescr: this.formData.serviceDescr, //说明
          files: this.fileKeys //文件
        }]
      }
      let res = await this.$service.SaveQuote(params)
      if (res && res.success) {
        this.$toast("酒店报价保存成功！");
        this.$eventHub.$emit('getVenueQuote')
      }
    },
    selectInvoiceType () {
      if (!this.isEdit ) {
        return
      }
      this.options = this.invoiceTypeList
      this.isShowPopup = true;
    },
    selectMethod (val = {}) {
      this.formData.invoiceType = val.txt
      this.isShowPopup = false;
    },
  }
}
</script>
<style scoped lang="scss">
.venueCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  padding: 0 .1rem .1rem;
  .venueTtitle {
    padding: 0.1rem 0.1rem 0.04rem;
    text-align: left;
    font-size: .16rem;
    font-weight: bold;
    color: #13161A;
    letter-spacing: -1px;
  }
  .venueInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &>div{
      flex: 1 1 50%;
      text-align: left;
    }
    span{
      font-size: .12rem;
      font-weight: 500;
      color: #909399;
      line-height: 15px;
      padding-bottom: .1rem;
      margin: 0 .1rem;
      text-align: left;
      border-bottom: 1px solid #F5F5F5;
    }
  }
}
</style>
