<template>
  <div class="teaCard">
    <div class="venueTtitle">{{quotation.itemTypeTxt}}</div>
    <div class="venueInfo">
      <div><span>茶歇名称:</span><span>{{ quotation.itemName }}</span></div>
      <div><span>茶歇人数:</span><span>{{ quotation.qty1 }}</span></div>
    </div>
    <div class="venueInfo">
      <div><span>开始时间:</span><span>{{ quotation.dtStart | formatDate('yyyy-MM-dd hh:mm')}}</span></div>
    </div>
    <div class="venueInfo">
      <div><span>结束时间:</span><span>{{ quotation.dtEnd | formatDate('yyyy-MM-dd hh:mm')}}</span></div>
    </div>
    <div class="venueInfo">
      <div><span>备注:</span><span>{{ quotation.descr }}</span></div>
    </div>
    <div class="venueTtitle felxBtn" @click="isShow = !isShow">
      <span>费用类型: {{formData.costType}}</span>
      <div class="retract">
        <svg class="icon" aria-hidden="true" v-show="isShow">
          <use xlink:href="#iconarrow-up"></use>
        </svg>
        <svg class="icon" aria-hidden="true" v-show="!isShow">
          <use xlink:href="#iconarrowdown"></use>
        </svg>
      </div>
    </div>
    <div class="processBody" :class="isShow ? 'showBody' : 'hiddenBody'">
      <div>
        <van-field v-model="formData.qty" label="数量" :disabled="true"/>
      </div>
      <div>
        <van-field v-model="formData.minQty" label="保底数量" placeholder="请输入保底数量" type="number" :disabled="!isEdit"/>
      </div>
      <div>
        <van-field :value="updateAmount" label="单价" :disabled="true"/>
      </div>
      <div>
        <van-field v-model="formData.amount" label="总金额" placeholder="请输入总金额" type="number" :required="true" :disabled="!isEdit"/>
      </div>
      <div>
        <van-field v-model="formData.outPrice" label="对外价格" placeholder="请填写对外价格" type="number" :disabled="!isEdit"/>
      </div>
      <div>
        <van-field v-model="formData.invoiceType" label="发票类型" placeholder="请选择发票类型" :required="true" :disabled="true" @click.native.stop='selectInvoiceType'/>
      </div>
      <div>
        <van-field v-model="formData.taxRate" label="税率" type="number" placeholder="请输入税率" :required="true" :disabled="!isEdit" @blur="onInput"/>
      </div>
      <div>
        <van-field v-model="formData.descr" label="报价说明" rows="2" autosize type="textarea"  maxlength="400" show-word-limit placeholder="请输入报价说明" :disabled="!isEdit"/>
      </div>
        <div>
          <van-field v-model="formData.serviceDescr" label="服务描述" rows="2" autosize type="textarea" :required="isGsk" maxlength="400" show-word-limit placeholder="请输入服务描述" :disabled="!isEdit"/>
        </div>
    </div>
    <select-type-list :isShowPopup="isShowPopup" :listData="options" :selectCallbackMethod="selectMethod"></select-type-list>
  </div>
</template>
<script>
import { Field } from 'vant';
import selectTypeList from "../selectTypeList.vue";
export default {
  name: 'teaQuote',
  components: {
    [Field.name]: Field,
    selectTypeList
  },
  props: {
    quotation: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    updateAmount () {
      return  ((this.formData.amount ||0) / (this.formData.qty || 1)).toFixed(2)
    },
  },
  data () {
    return {
      formData: {},
      isShow: true,
      isEdit:true,
      options: [],
      invoiceTypeList: [],
      isShowPopup:false,
    }
  },
  watch: {
    quotation () {
      let expenses = this.quotation.expenses[0]
      this.formData = {
        expenseId: expenses.expenseId,
        costType: expenses.costType,
        qty: Number(expenses.qty),
        minQty: expenses.minQty,
        price: expenses.price,
        outPrice: expenses.outPrice,
        amount: Number(expenses.amount),
        descr: expenses.descr,
        serviceDescr: expenses.serviceDescr,
        files: []
      }
    }
  },
  created() {
    this.isEdit = this.$SmartStorage.get('isEdit')
    this.invoiceTypeList = this.$SmartStorage.get('invoiceTypeList')
    let expenses = this.quotation.expenses[0]
    this.formData = {
      expenseId: expenses.expenseId,
      costType: expenses.costType,
      qty: Number(expenses.qty),
      minQty: expenses.minQty,
      price: expenses.price,
      outPrice: expenses.outPrice,
      amount: Number(expenses.amount),
      invoiceType: expenses.invoiceType,
      taxRate: expenses.taxRate,
      descr: expenses.descr,
      serviceDescr: expenses.serviceDescr,
      files: []
    }
  },
  methods:{
    submit() {
      let toast = this.veryFilter();
      if (toast) {
        this.$toast(toast);
        return {veryFilter: false, data: []};
      }
      let taxRate = Number(this.formData.taxRate) / 100;
      let amount = Number(this.formData.amount)
      this.formData.tax = Number((amount / (1 + taxRate) * taxRate).toFixed(2)) // 税额
      return {veryFilter: true, data: this.formData};
    },
    veryFilter () {
      let toast = '';
      if (!this.formData.amount) {
        toast = '请输入茶歇总金额！';
        return toast;
      }
      if (!this.formData.invoiceType) {
        toast = '请选择发票类型！';
        return toast;
      }
      if (!this.formData.taxRate) {
        toast = '请输入税率！';
        return toast;
      }
      if (this.isGsk && !this.formData.serviceDescr) {
        toast = '请输入服务描述！';
        return toast;
      }
    },
    onInput() {
      const value = this.formData.taxRate;
      if (value === null || value === undefined || value === '') {
        return null; // If the control is empty, don't show an error
      }
      const regExp = /^[1-9]\d*$/;
      if (regExp.test(value)) {
        return null; // The control is a positive integer, so return null (no error)
      } else {
        this.formData.taxRate = ''
        this.$toast("请填写有效的正整数！");
      }
    },
    selectInvoiceType () {
      if (!this.isEdit ) {
        return
      }
      this.options = this.invoiceTypeList
      this.isShowPopup = true;
    },
    selectMethod (val = {}) {
      this.formData.invoiceType = val.txt
      this.isShowPopup = false;
    },
  }
}
</script>
<style scoped lang="scss">
.teaCard {
  .venueTtitle {
    padding: 0.1rem 0.1rem 0.04rem;
    text-align: left;
    font-size: .16rem;
    font-weight: bold;
    color: #13161A;
    letter-spacing: -1px;
  }
  .venueInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &>div{
      flex: 1 1 50%;
      text-align: left;
    }
    span{
      font-size: .12rem;
      font-weight: 500;
      color: #909399;
      line-height: 15px;
      padding-bottom: .1rem;
      margin: 0 .1rem;
      text-align: left;
      border-bottom: 1px solid #F5F5F5;
    }
  }
}
</style>
