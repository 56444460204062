<template>
  <div>
    <div class="fileCard" v-for="(item, index) in supportingDocumentsData" :key="index">
      <div class="fileInfoItem">
        <p><span>供应商:</span><span>{{item.supplierName}}</span></p>
        <p><span>类别:</span><span>{{item.catalog}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>上传人:</span><span>{{item.uploader}}</span></p>
      </div>
      <div class="fileInfoItem">
        <p><span>上传时间:</span><span>{{item.dtUpload | formatDate('yy-MM-dd hh:mm:ss')}}</span></p>
      </div>
      <div v-if="!item.fileType" class="imgContainer">
        <template>
          <div class="imgBox">
            <img class="img" :src="item.uri" :title="item.fileName">
          </div>
        </template>
      </div>
      <div v-else-if="['.png', '.jpg', '.jpeg', 'bmp'].includes(item.fileType)">
        <viewer :images="[item.uri]" class="imgContainer">
          <template>
            <div class="imgBox">
              <img class="img" :src="item.uri" :title="item.fileName">
            </div>
          </template>
        </viewer>
      </div>
      <div v-else class="imgContainer">
        <template>
          <div class="imgBox" @click="downloadFile(item.uri)">
            <img class="img" :src="'https://spkg.smartmice.cn/resources/images/public/' + item.fileType.substr(1).toLowerCase() + '.png'" :title="item.fileName">
          </div>
        </template>
      </div>
    </div>
    <div class="fileCard" v-if="!supportingDocumentsData.length">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
export default {
  data () {
    return {
      supportingDocumentsData: []
    }
  },
  created() {
    this.queryProtocol()
  },
  methods: {
    async queryProtocol () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId') || null,
        tenantId: this.$SmartStorage.get('tenantId'),
      }
      let res = await this.$service.queryFiles(params)
      if (res && res.success) {
        this.supportingDocumentsData = res.content;
      }
    },
    downloadFile (url) {
      window.open(url)
    }
  }
}
</script>
<style scoped="scoped" lang="scss">
.fileCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: 0.25rem .1rem .1rem;
  background: #ffffff;
  position: relative;
  padding: .1rem;
  .fileInfoItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .05rem;
    p{
      flex: 1 1 50%;
      text-align:left
    }
  }
}
.imgContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .imgBox {
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 5px;
    position: relative;
    .img {
      width: 56px;
      height: 56px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
